// src/components/skills/Skills.jsx
import React, { useState, useEffect } from 'react';
import './skills.css';
import { fetchSkillsData } from './api';
import Backend from './Backend';
import Frontend from './Frontend';

const Skills = () => {
    const [skills, setSkills] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getSkillsData = async () => {
            try {
                const data = await fetchSkillsData();
                console.log("Fetched Skills Data:", data);
                if (data && Array.isArray(data) && data.length > 0 && typeof data[0] === 'object') {
                    setSkills(data[0]);
                } else {
                    setSkills({});
                }
                setLoading(false);
            } catch (err) {
                console.error('Error Fetching Data:', err);
                setError('Error Fetching Data');
                setLoading(false);
            }
        };
        getSkillsData();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <section className="skills section" id="skills">
            <h2 className="section__title">Skills</h2>
            <span className="section__subtitle">My technical level</span>
            <div className="skills__container container grid">
                {Object.keys(skills).map((category) => (
                    <SkillsCategory key={category} title={category} skills={skills[category]} />
                ))}
            </div>
        </section>
    );
};

const SkillsCategory = ({ title, skills }) => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">{title}</h3>
            <div className="skills__box">
                {skills && skills.map((skill) => (
                    <div className="skills__group" key={skill.skill_id}>
                        <div className="skills__data">
                            <i className="bx bx-badge-check"></i>
                            <div>
                                <h3 className="skills__name">{skill.title}</h3>
                                <span className="skills__level">{skill.severity}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Skills;
