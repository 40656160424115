import axios from 'axios';
import config from '../../config';

export const fetchSkillsData = async () => {
  try {
    const response = await axios.get(`${config.apiUrl}/info/skills`);
    return response.data;
  } catch (error) {
    console.error('Error fetching skills data:', error);
    return [];
  }
};