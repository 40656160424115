import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';

const Social = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/info/contact`);
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error Fetching Data:', error);
        setError(error.message);
        setLoading(false);
      }
    };
    fetchSocialLinks();
  }, []);

  return (
    <div className="home__social">
      {loading ? (
        <p>Loading...</p>
      ) : error ? ( // اضافه کردن شرط برای نمایش پیام خطا
        <p>{error}</p>
      ) : (
        <>
          <a href={`https://instagram.com/${data[0].instagram}`} className="home__social-icon" target="_blank">
            <i className="uil uil-instagram"></i>
          </a>
          <a href={`https://www.linkedin.com/in/${data[0].linkdin}`} className="home__social-icon" target="_blank">
            <i className="bx bxl-linkedin-square"></i>
          </a>
          <a href={`https://twitter.com/${data[0].twitter}`} className="home__social-icon" target="_blank">
            <i className="uil uil-twitter"></i>
          </a>
        </>
      )}
    </div>
  );
};

export default Social;