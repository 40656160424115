import axios from 'axios';
import React, { useEffect, useState } from 'react'
import config from '../../config';



const Info = () => {

    const [userdata, setuserData] = useState([null]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}/info/user`);
                setuserData(response.data);
                setLoading(false);
            }
            catch (error) {
                console.error('Error Fetching Data:', error);
                setLoading(true);
            }
        };
        fetchData();
    }, []);
    return (
        <div className="about__info grid">
            {loading ? (
                <p>
                    Loading...
                </p>
            ) : (
                <>
                    <div className="about__box">
                        <i className="bx bx-award about__icon"></i>
                        <h3 className="about__title">
                            Experience
                        </h3>
                        <span className="about__subtitle">
                            {userdata[0].Experience} Years Working
                        </span>
                    </div>
                    <div className="about__box">
                        <i className="bx bx-briefcase about__icon"></i>
                        <h3 className="about__title">
                            Completed
                        </h3>
                        <span className="about__subtitle">
                            48 + Projects
                        </span>
                    </div>
                    <div className="about__box">
                        <i className="bx bx-support about__icon"></i>
                        <h3 className="about__title">
                            Support
                        </h3>
                        <span className="about__subtitle">
                            Online 24/7
                        </span>
                    </div></>
            )}
        </div>
    )
}

export default Info
