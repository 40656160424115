// src/components/home/Testimonial.jsx
import React, { useState, useEffect } from 'react';
import "./testimonial.css";
import { fetchData } from './Data';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from 'swiper/modules';

const Testimonial = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            const fetchedData = await fetchData();
            setData(fetchedData);
            setLoading(false);
        };
        getData();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    return ( 
        <section className="testimonial container section" id="testimonial">
            <h2 className="section__title">My clients say</h2>
            <span className="section__subtitle">Testimonial</span>
            

            <Swiper className="testimonials__container"
                loop={true}
                grabCursor={true}
                spaceBetween={24}
                pagination={{
                    clickable: true,
                }}
                breakpoints={{
                    576: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 48,
                    },
                }}
                modules={[Pagination]}
            >
                {data.map(({ id, image, title, Description }) => (
                    <SwiperSlide className="testimonial__card" key={id}>
                        <img src={image} alt="" className="testimonial__img" />
                        <h3 className='testimonial__name'>{title}</h3>
                        <p className='testimonial__description'>{Description}</p>
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
};

export default Testimonial;
