import React, { useState, useEffect } from 'react';
import './services.css';
import config from '../../config';

const Services = () => {
    const [toggleState, setToggleState] = useState(0);
    const [services, setServices] = useState([]);
    const [serviceDetails, setServiceDetails] = useState([]);

    useEffect(() => {
        // Fetching services data from the API
        fetch(`${config.apiUrl}/info/services`)
            .then(response => response.json())
            .then(data => {
                setServices(data);
                setServiceDetails(data);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const toggleTab = (index) => {
        setToggleState(index);
    }

    return (
        <section className="services section" id="services">
            <h2 className="section__title">Services</h2>
            <span className="section__subtitle">What I offer</span>
            <div className="services__container grid">
                {services.map((service, index) => (
                    <div className="services__content" key={service.ID}>
                        <div>
                            <i className={`uil ${service.iconClass} services__icon`}></i>
                            <h3 className="services__title">{service.title}</h3>
                        </div>
                        <span className="services__button" onClick={() => toggleTab(service.ID)}>
                            View More
                            <i className="uil uil-arrow-right services__button-icon"></i>
                        </span>
                        <div className={toggleState === service.ID ? "services__modal active-modal" : "services__modal"}>
                            <div className="services__modal-content">
                                <i className="uil uil-times services__modal-close" onClick={() => toggleTab(0)}></i>
                                <h3 className="services__modal-title">{service.title}</h3>
                                <p className="services__modal-description">{service.Description}</p>
                                <ul className="services__modal-services grid">
                                    {serviceDetails.filter(detail => detail.service_id === service.ID).map(detail => (
                                        <li className="services__modal-service" key={detail.ID}>
                                            <i className="uil uil-check-circle services__modal-icon"></i>
                                            <p className="services__modal-info">{detail.Condition}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Services;
