import React, { useState, useEffect } from 'react';
import './header.css';
import axios from 'axios';
import config from '../../config';

const Header = () => {

    /*================== Change Background Header ==================*/
    window.addEventListener("scroll", function () {
        const header = document.querySelector(".header");
        if (this.scrollY > 80) header.classList.add("scroll-header");
        else header.classList.remove("scroll-header");
    })

    /*================== Toggle Menu ==================*/
    const [Toggle, showMenu] = useState(false);
    const [userdata, setuserData] = useState([null]);
    const [error, setError] = useState(null);
    const [activeNav, setActiveNav] = useState(false);
    const [loading, setLoading] = useState("#home");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}/info/user`);
                setuserData(response.data);
                setLoading(false);
            }
            catch (error) {
                console.error('Error Fetching Data:', error);
                setError(error.message);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    return (
        <header className="header">
            {loading ? (
                <p>Loading...</p>
            ) : error ? ( // اضافه کردن شرط برای نمایش پیام خطا
                <p>{error}</p>  
            ) : (
                <nav className="nav container">
                    <a href="index.html" className="nav__logo">{userdata[0].first_name}</a>
                    <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
                        <ul className="nav__list grid">
                            <li className="nav__item">
                                <a href="#home" onClick={() => setActiveNav("#home")} className={activeNav === "#home" ? "nav__link active-link" : "nav__link"}>
                                    <i className="uil uil-estate nav__icon"></i> Home
                                </a>
                            </li>
                            <li className="nav__item">
                                <a href="#about" onClick={() => setActiveNav("#about")} className={activeNav === "#about" ? "nav__link active-link" : "nav__link"}>
                                    <i className="uil uil-user nav__icon"></i> About
                                </a>
                            </li>
                            <li className="nav__item">
                                <a href="#skills" onClick={() => setActiveNav("#skills")} className={activeNav === "#skills" ? "nav__link active-link" : "nav__link"}>
                                    <i className="uil uil-file-alt nav__icon"></i> Skills
                                </a>
                            </li>
                            <li className="nav__item">
                                <a href="#services" onClick={() => setActiveNav("#services")} className={activeNav === "#services" ? "nav__link active-link" : "nav__link"}>
                                    <i className="uil uil-briefcase-alt nav__icon"></i> Services
                                </a>
                            </li>
                            <li className="nav__item">
                                <a href="#testimonial" onClick={() => setActiveNav("#testimonial")} className={activeNav === "#testimonial" ? "nav__link active-link" : "nav__link"}>
                                    <i className="uil uil-scenery nav__icon"></i> Testimonial
                                </a>
                            </li>
                            <li className="nav__item">
                                <a href="#contact" onClick={() => setActiveNav("#contact")} className={activeNav === "#contact" ? "nav__link active-link" : "nav__link"}>
                                    <i className="uil uil-message nav__icon"></i> Contact
                                </a>
                            </li>
                        </ul>
                        <i className="uil uil-times nav__close" onClick={() => showMenu(!Toggle)}></i>
                    </div>

                    <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
                        <i className="uil uil-apps"></i>
                    </div>
                </nav>
            )}


        </header>
    )
}

export default Header
