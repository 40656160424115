// src/components/home/Data.jsx
import axios from 'axios';
import Image1 from "../../assets/testimonial1.png";
import Image2 from "../../assets/testimonial2.png";
import Image3 from "../../assets/testimonial3.png";
import config from '../../config';

const images = [Image1, Image2, Image3];

export const fetchData = async () => {
    try {
        const response = await axios.get(`${config.apiUrl}/info/testimonial`);
        const data = response.data.map((item, index) => ({
            id: item.ID,
            image: images[index % images.length],
            title: `${item.first_name} ${item.last_name}`,
            Description: item.Description,
        }));
        return data;
    } catch (error) {
        console.error('Error Fetching Data:', error);
        return [];
    }
};