import React, { useEffect, useState } from 'react'
import "./footer.css"
import config from '../../config';
import axios from 'axios';

const Footer = () => {
    const [userdata, setuserData] = useState([null]);
    const [userdata2, setuserData2] = useState([null]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}/info/contact`);
                const response2 = await axios.get(`${config.apiUrl}/info/user`);
                setuserData(response.data);
                setuserData2(response2.data);
                setLoading(false);
            }
            catch (error) {
                console.error('Error Fetching Data:', error);
                setLoading(true);
            }
        };
        fetchData();
    }, []);
    return (

        <footer className="footer">
            {loading ? (
                <p>
                    Loading...
                </p>
            ) : (
                <>
                    <div className="footer__container container ">
                        <h1 className="footer__title">{userdata2[0].first_name}</h1>

                        <ul className="footer__list">
                            <li>
                                <a href="#about" className="footer__link">About</a>
                            </li>

                            <li>
                                <a href="#testimonial" className="footer__link">Testimonial</a>
                            </li>

                            <li>
                                <a href="#services" className="footer__link">Services</a>
                            </li>
                        </ul>

                        <div className="footer__social">
                            <a href={`https://instagram.com/${userdata[0].instagram}`} className="footer__social-link" target="_blank">
                                <i className="bx bxl-instagram"></i>
                            </a>
                            <a href={`https://www.linkedin.com/in/${userdata[0].linkdin}/`} className="footer__social-link" target="_blank">
                                <i className="bx bxl-linkedin-square"></i> 
                            </a>
                            <a href={`https://twitter.com/${userdata[0].instagram}`} className="footer__social-link" target="_blank">
                                <i className="bx bxl-twitter"></i>
                            </a>
                        </div>
                        <span className="footer__copy">
                            &#169; YooKa. All rigths reserved
                        </span>
                    </div>
                </>
            )}
        </footer>
    )
}

export default Footer
